$theme-colors: (
        "primary": #E74C3C,
        "secondary": #F2DD72,
        "success": #58D68D,
        "info": #3498DB,
        "warning": #F5B041,
        "danger": #7B241C,
);

// Import bootstrap
@import "~bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css?family=Signika:300,400&display=swap');

html, body {
  font-family: "Signika", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

html, body {
  height: 100%;
}

body, #root {
  height: 100%;
  overflow-x: hidden;
  margin: 0;
  font-family: "Signika", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

section.padded {
  padding: 20px 10%;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0 !important;
  padding-left: 0 !important;
}